<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-manual-assignment">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignación Manual</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-11">
              <label>Sitio</label>
              <v-select
                v-model="form.sitio_id"
                placeholder="Sitio"
                label="nombre"
                class="border rounded shadow-sm p-0"
                :class="
                  $v.form.sitio_id.$invalid ? 'border-danger' : 'border-success'
                "
                :options="listasForms.sitios"
                @search="buscarSitios"
                :reduce="(sitio) => sitio.id"
              ></v-select>
            </div>
            <!-- <div class="form-group col-md-11">
              <label for="origen">Empresa</label>
              <v-select
                v-model="form.empresa_id"
                placeholder="empresa"
                label="razon_social"
                class="border rounded shadow-sm p-0"
                :options="listasForms.empresas"
                :class="
                  $v.form.empresa_id.$invalid
                    ? 'border-danger'
                    : 'border-success'
                "
                @search="buscarEmpresas"
                :reduce="(empresa) => empresa.id"
              ></v-select>
            </div> -->
            <div class="form-group col-md-11">
              <label for="origen">Conductor</label>
              <v-select
                v-model="form.asignacion_id"
                placeholder="Conductor"
                label="nombre"
                class="border rounded shadow-sm p-0"
                :options="listasForms.conductores"
                :class="
                  $v.form.asignacion_id.$invalid
                    ? 'border-danger'
                    : 'border-success'
                "
                @search="buscarConductores"
                :reduce="(conductor) => conductor.id"
              ></v-select>
            </div>
            <div class="row">
              <div class="col-md-11 mt-2 ml-2">
                <div class="form-group">
                  <label for="codigo_sup">Tipo Bono</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.tipo_bono"
                    :class="
                      $v.form.tipo_bono.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value="1">Alkosto</option>
                  </select>
                </div>
              </div>
              <div class="col-md-11 mt-2 ml-2">
                <div class="form-group">
                  <label for="codigo_sup">Monto Bono</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.monto_bono"
                    :class="
                      $v.form.monto_bono.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value>Seleccione...</option>
                    <option
                      v-for="montoBono in $parent.listasForms.monto_bonos"
                      :key="montoBono.numeracion"
                      :value="montoBono.numeracion"
                    >
                      {{ montoBono.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-11 mt-2 ml-2">
                <div class="form-group">
                  <label for="codigo_sup">Justificación</label>
                  <textarea
                    v-model="form.justificacion"
                    class="form-control form-control-sm"
                    :class="
                      $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid"
              @click="asignarBonos()"
            >
              Asignar Bono
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "AsignacionManual",
  components: {
    vSelect,
  },
  data() {
    return {
      form: {
        asignacion_type: "App\\Conductor",
        asignacion_id: null,
        sitio_id: null,
        //empresa_id: null,
        tipo_bono: 1,
        monto_bono: null,
        justificacion: null,
      },
      listasForms: {
        conductores: [],
        empresas: [],
        sitios: [],
      },
    };
  },
  validations: {
    form: {
      tipo_bono: {
        required,
      },
      monto_bono: {
        required,
      },
      /* empresa_id: {
        required,
      }, */
      asignacion_id: {
        required,
      },
      sitio_id: {
        required,
      },
      justificacion: {
        required,
      },
    },
  },
  methods: {
    buscarSitio(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.sitios = respuesta;
            me.form.sitio_id = me.listasForms.sitios.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    asignarBonos() {
      let mi = this;
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/hidrocarburos/asignarBonos",
          data: this.form,
        })
          .then((response) => {
            this.$refs.closeModal.click();
            this.limpiarForm();
            this.$swal({
              icon: "success",
              title: response.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          })
          .catch((e) => {
            this.$parent.cargando = false;
            if (e.response.status == 404) {
              this.error = e.response.data.error;
              if (this.error) {
                this.$swal({
                  icon: "error",
                  title: this.error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.$parent.cargando = false;
          });
      }
    },

    limpiarForm() {
      this.form = {
        asignacion_type: "App\\Conductor",
        asignacion_id: null,
        //empresa_id: null,
        tipo_bono: 1,
        monto_bono: null,
        justificacion: null,
      };
    },

    buscarConductores(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.conductores = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    /* buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.empresas = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    }, */

    buscarSitios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.sitios = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            loading(false);
          });
      }
    },
  },
};
</script>
